<template>
  <ViaggiTreno isArrivo :title="tLabel('menu_arrivi')" :faseOperativa="faseOperativa" />
</template>

<script>
import ViaggiTreno from "./ViaggiTreno";

export default {
  name: 'viaggiTrenoArrivi',
  props: {
    faseOperativa: {
      type: String,
      default: null
    }
  },
  components: {ViaggiTreno}
};
</script>